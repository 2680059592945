import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Layout, Menu, MenuProps, Badge } from 'antd'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { changeIsFlightHome, changeFlightData, changeLoadMoreData, changeOriginData, changeCarriers, changeCheckedCarriers, changeCheckedChannel } from '@/store/features/flight/slice'
import { useAppDispatch } from "@/store/hook";
import { selectUserInfo } from '@/store/features/user/slice'
import { useSelector } from "react-redux";
import { getQuestionUnreadNum } from '@/services/question'
type MenuItem = Required<MenuProps>['items'][number]

// 路由懒加载
interface Props {
  style?: React.CSSProperties
}

function getItem(params: {
  label: React.ReactNode
  key?: React.Key | null
  path?: string
  icon?: React.ReactNode
  children?: MenuItem[]
  type?: 'group'
  onClick: MenuProps['onClick']
}): MenuItem {
  return params as MenuItem
}

const NavList: React.FC<Props> = ({ ...reset }) => {
  const dispatch = useAppDispatch(); // 取状态管理里的数据

  const navigate = useNavigate()
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location])
  const userInfo = useSelector(selectUserInfo);
  const [qaCount, setQaCount] = useState(0);

  const selectedKeys = useMemo(() => {
    const p = pathname.split('/')[1]
    switch (p) {
      case 'booking-detail':
        return '/bookings'
      case 'refund-detail':
        return '/refund'
      case 'question-detail':
        return '/question'
      case 'flight-detail':
        return '/flight'
      default:
        return pathname
    }
  }, [pathname])

  const handleClick = useCallback(
    (path: string) => {
      if (path === '/flight') {
        dispatch(changeIsFlightHome(true));
        dispatch(changeOriginData([]));
        dispatch(changeFlightData([]));
        dispatch(changeLoadMoreData([]));
        dispatch(changeCarriers([]));
        dispatch(changeCheckedCarriers([]));
        dispatch(changeCheckedChannel([]));
      }
      navigate(path);
    },
    [navigate]
  )



useEffect(() => {

    // 获取Q&A未读新消息数量
    const fetchQaCount = async () => {
      getQuestionUnreadNum()
        .then((response) => {
          setQaCount(response?.count ?? 0);
        })
    };

    // 定时获取Q&A未读新消息数量
    const intervalId = setInterval(fetchQaCount, 30000); //30秒钟刷新一次
    return () => {
      clearInterval(intervalId);
    };
}, []);


  let menuList = [
    // getItem({
    //   label: 'Dashboard',
    //   key: '/dashboard',
    //   onClick: ({ key }) => {
    //     handleClick(key)
    //   },
    // }),
    getItem({
      label: 'Flight',
      key: '/flight',
      onClick: ({ key }) => {
        handleClick(key)
      },
    }),
    getItem({
      label: 'Bookings',
      key: '/bookings',
      onClick: ({ key }) => {
        handleClick(key)
      },
    }),
    getItem({
      label: 'Refund',
      key: '/refund',
      onClick: ({ key }) => {
        handleClick(key)
      },
    }),
    getItem({
      label: 'Change',
      key: '/change',
      onClick: ({ key }) => {
        handleClick(key)
      },
    }),
    // getItem({
    //   label: 'Ancillarys',
    //   key: '/ancillarys',
    //   onClick: ({ key }) => {
    //     handleClick(key)
    //   },
    // }),
    getItem({
      label: 'Balance',
      key: '/balance',
      onClick: ({ key }) => {
        handleClick(key)
      },
    }),
    getItem({
      label: (
        <span>
          Q&A
          <Badge count={qaCount} style={{ position: 'absolute', top: '-30px', right: '-20px' }} />
        </span>
      ),
      key: '/question',
      onClick: ({ key }) => {
        handleClick(key)
      },
    })
  ];

  if (userInfo?.airline_menu) {
    menuList.push(getItem({
      label: 'TechHub',
      key: '/airline',
      onClick: ({ key }) => {
        handleClick(key)
      },
      children: [ // Add a children array for submenu items
        getItem({
          label: 'Airline setting',
          key: '/airline',
          onClick: ({ key }) => {
            handleClick(key)
          },
        }),
      ],
    }),)
  }

  menuList.push(getItem({
    label: 'Report',
    key: '/report',
    onClick: ({ key }) => {
      handleClick(key)
    },
  }),)

  const menuItems: MenuItem[] = useMemo(
    () => menuList,
    [handleClick, qaCount]
  )

  return (
    <Menu
      mode="horizontal"
      items={menuItems}
      selectedKeys={[selectedKeys]}
      theme="light"
      {...reset}
    />
  )
}

export default NavList
